var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Entry',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Inputs with type "),_c('code',[_vm._v("range")]),_vm._v(" render using Bootstrap v4's "),_c('code',[_vm._v(".custom-range")]),_vm._v(" class. The track (the background) and thumb (the value) are both styled to appear the same across browsers. "),_c('br'),_c('br'),_vm._v(" Range inputs have implicit values for "),_c('code',[_vm._v("min")]),_vm._v(" and "),_c('code',[_vm._v("max")]),_vm._v(" of "),_c('code',[_vm._v("0")]),_vm._v(" and "),_c('code',[_vm._v("100")]),_vm._v(" respectively. You may specify new values for those using the "),_c('code',[_vm._v("min")]),_vm._v(" and "),_c('code',[_vm._v("max")]),_vm._v(" props. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('step')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }